* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

.hidden {
  display: none!important;
}

a,
button {
  text-decoration: none;
  color: #cccccc;
}

.padding {
  padding-left: 5vw;
  padding-right: 5vw;
}

@keyframes color-change-2x {
  0% {
    background: #02021f;
  }
  100% {
    background: #300349;
  }
}

.App {
  background-color: black;
  background: #23043a;
  animation: color-change-2x 2s linear infinite alternate both;
}
.header {
  z-index: 2;
}

#canvas1 {
  width: 100%;
  height: 100vh;
}

h1 {
  font-size: 8rem;
}
h2 {
  font-size: 4rem;
}
h3 {
  font-size: 3rem;
}
h4 {
  font-size: 2rem;
  font-weight: bold;
}
p {
  font-size: 1.5rem;
}
