header{
    height: 14vh;
    display: flex;
    align-items: center;
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    z-index: 3;
    transition-duration: 1s;
}
.minHeader{
    height: 10vh;
}
header, nav, ul {
    display: flex;
    align-items: center;
    width: 100%;
}

.mobile, .btns-menu-mobile{
    display: none;
  }
  
  button {
    all: unset;
    cursor: pointer;
  }
  nav>img{
    cursor: pointer;
  }
  nav a {
    text-transform: uppercase;
  }
  
  ul {
    list-style: none;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
  
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5vw;
  }
  
  li a{
    text-align: center;
    cursor: pointer;
    margin: 2% 0 0 1%;
    padding: .5rem 1vw;
    border: 0.4vh solid rgba(255, 255, 255, 0.4);
    border-right: 0.1vh solid rgba(255, 255, 255, 0.2);
    border-bottom: 0.1vh solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    transition: 0.6s;
    overflow: hidden;
    font-weight: bold;
    font-size: 1rem;
    width: max-content;
    height: max-content;
  }
  
  li a:hover{
    font-size: 1.1rem;
    color: #9649d1;
  }
  
  li a::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100px;
    background: rgba(255, 255, 255, 0.5);
    transform: skew(45deg) translateX(190px);
    transition: 0.5s;
  }
  li a:hover::before{
    transform: skew(45deg) translateX(-150px);
  }
  
  
  .logo {
    background: -moz-linear-gradient(
      top,
      #0a1556 2%,
      #9649d1 80%,
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #0a1556 2%,
      #9649d1 80%,
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #0a1556 2%,
      #9649d1 80%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a1556', endColorstr='#9649d1',GradientType=0 ); /* IE6-9 */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-size: 3rem;
    cursor: pointer;
    margin: 0;
  }
  
  .logo a, .logo a:hover, .logo a::before, .logo a:hover::before{
    all: unset;
  }
  
  .logo a, .logo a:hover {
    text-transform: initial;
    color: white;
  }
  