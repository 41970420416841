

.section_cases {
  margin-top: -10vh;
  background: #360958;
  -webkit-clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
  padding-top: 10%;
}

.div-carousel {
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.div-carousel * {
  color: white;
}

.div-carousel a:hover {
  color: #9649d1;
}

.div-carousel > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-carousel>div{
  display: flex;
}
.text-carousel a {
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  margin: 1rem 1rem 0 0;
  padding: .5rem 1vw;
  border: 0.4vh solid rgba(255, 255, 255, 0.4);
  border-right: 0.1vh solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.1vh solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  transition: 0.6s;
  overflow: hidden;
  font-weight: bold;
  font-size: 1rem;
  width: max-content;
  height: max-content;
}

.text-carousel a:hover {
  font-size: 1.1rem;
}

.text-carousel a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 100px;
  background: rgba(255, 255, 255, 0.5);
  transform: skew(45deg) translateX(150px);
  transition: 0.4s;
}
.text-carousel a:hover::before {
  transform: skew(45deg) translateX(-150px);
}

.right-arrow,
.left-arrow {
  color: #ccccccc7;
  font-size: 3rem;
  cursor: pointer;
  height: 100%;
  transition: .5s;
  font-weight: bold;
}

.right-arrow:hover,
.left-arrow:hover {
  color: white;
}

.slider,
.slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider {
  gap: 2vw;
}
.slide {
  opacity: 0;
  align-self: initial;
}
.slide button {
  display: flex;
  gap: 10px;
}

@keyframes flip-in-hor-bottom {
  1% {
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

.active {
  animation: flip-in-hor-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s both;
  align-items: center;
}

.div-carousel img {
  width: 65rem;
  height: 40rem;
}

.text-carousel {
  padding-left: 3vh;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-self: flex-start;
}
.text-carousel  h2{
  font-weight: bold;
}
.text-carousel p{
  margin-bottom: 0;
}
.text-carousel button img{
  display: none;
  width: 1.5rem;
  height: 4rem;
}
.carousel-indicator {
  margin-top: 2%;
  display: flex;
  gap: 1vh;
}

.carousel-indicator p {
  cursor: pointer;
  opacity: 0.4;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-left-carousel {
  animation: slide-in-left 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s both;
}
.slide-in-right-carousel {
  animation: slide-in-right 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s both;
}

@keyframes slide-in-fwd-bottom {
  0% {
    transform: translateZ(-1400px) translateY(800px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

.slide-in-fwd-bottom {
  animation: slide-in-fwd-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
}

.indicator-active {
  opacity: 1 !important;
}
