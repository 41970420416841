.div-picture, .apresentation>h1, .apresentation>h2,.apresentation>p,.socialMedias>div,.buttonOne,.buttonTwo,.buttonThree,.buttonFour{
  opacity: 0;
}

@keyframes slide-in-right{0%{transform:translateX(1000px);opacity:0}100%{transform:translateX(0);opacity:1}}
@keyframes slide-in-top{0%{transform:translateY(-1000px);opacity:0}100%{transform:translateY(0);opacity:1}}
@keyframes slide-in-left{0%{transform:translateX(-1000px);opacity:0}100%{transform:translateX(0);opacity:1}}
@keyframes slide-in-bl{0%{transform:translateY(1000px) translateX(-1000px);opacity:0}100%{transform:translateY(0) translateX(0);opacity:1}}

@keyframes slide-in-bottom{0%{opacity:0}100%{opacity:1}}

@keyframes slide-in-bottomToTop{0%{transform:translateY(1000px);opacity:0}100%{transform:translateY(0);opacity:1}}

.slide-in-right{animation:slide-in-right 4s both}
.slide-in-top{animation:slide-in-top 4s both}
.slide-in-left{animation:slide-in-left 4s both}
.slide-in-bl{animation:slide-in-bl 4s both}
.slide-in-bottom1{animation:slide-in-bottom 4s 3s both}
.slide-in-bottom2{animation:slide-in-bottom 4s 3.5s both}
.slide-in-bottom3{animation:slide-in-bottom 4s 4s both}
.slide-in-bottom4{animation:slide-in-bottom 4s 4.5s both}

.slide-in-bottom{animation:slide-in-bottomToTop 5s cubic-bezier(.25,.46,.45,.94) both}

.section_about_me{
  background: #360958;
  height: max-content;
  display: flex;
  padding-top: 5vh;
  padding-bottom: 10%;
  justify-content: center;
  align-items: center;
  -webkit-clip-path: polygon(0 0,100% 0,100% 85%,0 95%);
  clip-path: polygon(0 0,100% 0,100% 85%,0 95%);
}
.aboutMe {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 100%;
  gap: 5vw;
}
.aboutMe * {
  color: white;
}
.aboutMe .myName {
  margin-left: 5vh;
  color: black;
  font-weight: bold;
  position: relative;
  top: 10rem;
}

.aboutMe img {
  height: 50rem;
}

.apresentation {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aboutMe h3 {
  margin-top: 1%;
}
.aboutMe p {
  margin-top: 1%;
}
.socialMedias {
  display: flex;
  padding: 0 5vh;
}
.socialMedias > div {
  border-image-slice: 1;
  border-width: 0.8vh;
  border-style: none none solid solid;
  width: 5rem;
  height: 5rem;
}

.socialMedias a {
  margin: 2rem 0 0 1vh;
  border: 0.4vh solid rgba(255, 255, 255, 0.4);
  border-right: 0.1vh solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.1vh solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  width: 5rem;
  height: 5rem;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  transition: 0.6s;
  overflow: hidden;
}

.socialMedias a:hover {
  transform: translateY(-1em);
}

.socialMedias a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 100px;
  background: rgba(255, 255, 255, 0.5);
  transform: skew(45deg) translateX(150px);
  transition: 1s;
}
.socialMedias a:hover::before {
  transform: skew(45deg) translateX(-150px);
}

.socialMedias a img {
  width: 3rem;
  height: 3rem;
  background: linear-gradient(to bottom, #9649d1 1%, #030330 70%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
  color: white;
}
