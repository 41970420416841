.btns-menu-mobile, .modal-case  {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: fixed;
  top: 10vh;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.95);
}

.modal-case {
    z-index: 2;
    gap: 2rem;
    justify-content: center;
    padding: 10vh;
}
.modal-case>*, .modal-case .text-carousel {
    text-align: center;
    width: 30vh;
    align-self: center;
    padding-left: 0;
    height: max-content;
}
.modal-case img {
    width: 20vh;
    margin: 2rem 0;
}
.modal-case h2 {
    font-size: 3rem;
    font-weight: bold;
}

.modal-case * {
  color: #fff;
}
.modal-case div{
    gap: 2rem;
}

.modal-case .text-carousel h4,
.modal-case .text-carousel p,
.modal-case .text-carousel{
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.modal-case .text-carousel > div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.modal-case a{
    text-decoration: initial;
    cursor: pointer;
}

.modal-case a:hover,
.modal-case a::before,
.modal-case a:hover::before{
    all: unset;
    cursor: pointer;
}
.modal-case .btn-close{
    position: absolute;
    top: 3vw;
    right: 3vw;
    padding: 1rem;
    cursor: pointer;
    width: 3rem;
}