

.section_stacks>div{
    display: flex;
    flex-direction: column;
    height: 40vh;
    justify-content: center;
    align-items: center;
}

.div-stacks > img {
  width: 4.5vw;
  z-index: 0;
}

.div-stacks {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3.1vw;
}



@keyframes bounce-in-fwd{0%{transform:scale(0);animation-timing-function:ease-in;opacity:0}38%{transform:scale(1);animation-timing-function:ease-out;opacity:1}55%{transform:scale(.7);animation-timing-function:ease-in}72%{transform:scale(1);animation-timing-function:ease-out}81%{transform:scale(.84);animation-timing-function:ease-in}89%{transform:scale(1);animation-timing-function:ease-out}95%{transform:scale(.95);animation-timing-function:ease-in}100%{transform:scale(1);animation-timing-function:ease-out}}

.bounce-in-fwd{animation:bounce-in-fwd 5.1s 2s both}

.canvas{
  display: flex;
  height: 60vh;
  width: 100%;
  margin-top: -50vh;
}