@media (max-width: 375.98px) {
  body {
    min-width: 285px;
  }
  html {
    font-size: 8px;
  }

  .padding {
    padding: 0 3vh;
  }
  header {
    width: 100%;
  }

  .mobile {
    display: initial;
  }

  nav,
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .nav-principal {
    justify-content: space-between;
    padding: 0 5vh;
  }

  li button p a {
    margin-right: 5px;
  }
  li a {
    display: none;
  }

  .btns-menu-mobile ul {
    flex-direction: column;
    gap: 5vh;
  }
  .btns-menu-mobile button a {
    display: initial;
    color: white;
  }

  .logoLi,
  .logoLi:hover {
    all: unset;
  }

  .logoLi a,
  .logoLi > * {
    font-size: 3rem;
  }

  ul {
    width: max-content;
    padding: 0 !important;
    margin: 0 !important;
  }

  .pixelPerfect {
    display: none;
  }
  .introductionText .h1 {
    font-size: 8rem;
    text-transform: lowercase;
  }
  .aboutMe {
    width: 100%;
    flex-direction: column;
    padding: 5rem 0 10rem 0;
    height: max-content;
  }
  .apresentation {
    width: 100%;
  }
  .aboutMe h1,
  .aboutMe h3 {
    text-align: center;
  }
  .div-picture {
    display: none;
  }

  .canvas {
    display: none;
  }
  .section_stacks > div {
    width: 100%;
    height: 30vw;
  }
  .div-stacks > img {
    width: 10vw;
  }

  .section_cases {
    margin-top: 0;
    -webkit-clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
  }
  .slider {
    margin-top: 15rem;
    height: max-content;
  }
  .slide {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    align-items: center;
  }
  
  .div-carousel .carousel-image {
    width: 50vw;
    height: 90vw;
  }
  .slide{
    padding: 1rem;
    border-radius: 10px;
    box-shadow: inset 0 0 1em #190429;
  }
  .text-carousel {
    height: 5rem;
    padding: 0;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .text-carousel button img {
    display: initial;
  }
  .right-arrow, .left-arrow {
    margin: 0 5vw;
  }

  .text-carousel h2 {
    font-size: 2rem;
    margin: 0;
  }

  .text-carousel h4,
  .text-carousel p,
  .text-carousel > div {
    display: none;
  }


  .btns-menu-mobile a,
  .text-carousel a {
    all: unset;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2rem;
  }

  .btns-menu-mobile a:hover,
  .text-carousel a:hover,
  .btns-menu-mobile a:hover::before,
  .text-carousel a:hover::before,
  .btns-menu-mobile a::before,
  .text-carousel a::before {
    all: unset;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2rem;
  }
  footer {
    flex-direction: column;
  }

}

@media (min-width: 376px) and (max-width: 767.98px) {
  html {
    font-size: 8px;
  }

  .padding {
    padding: 0 3vh;
  }
  header {
    width: 100%;
  }

  .mobile {
    display: initial;
  }

  nav,
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .nav-principal {
    justify-content: space-between;
    padding: 0 5vh;
  }

  li button p a {
    margin-right: 5px;
  }
  li a {
    display: none;
  }

  .btns-menu-mobile ul {
    flex-direction: column;
    gap: 5vh;
  }
  .btns-menu-mobile button a {
    display: initial;
    color: white;
  }

  .logoLi,
  .logoLi:hover {
    all: unset;
  }

  .logoLi a,
  .logoLi > * {
    font-size: 3rem;
  }

  ul {
    width: max-content;
    padding: 0 !important;
    margin: 0 !important;
  }

  .pixelPerfect {
    display: none;
  }
  .introductionText .h1 {
    font-size: 8rem;
  }
  .aboutMe {
    width: 100%;
    flex-direction: column;
    padding: 8rem 0;
    height: max-content;
  }
  .apresentation {
    width: 100%;
  }
  .aboutMe h1,
  .aboutMe h3 {
    text-align: center;
  }
  .div-picture {
    display: none;
  }

  .canvas {
    display: none;
  }
  .section_stacks > div {
    width: 100%;
    height: 30vw;
  }
  .div-stacks > img {
    width: 10vw;
  }

  .section_cases {
    margin-top: 0;
    -webkit-clip-path: polygon(0 7%, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 7%, 100% 0, 100% 100%, 0 100%);
  }
  .slider {
    margin-top: 15rem;
    height: max-content;
  }
  .slide {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    align-items: center;
  }
  .div-carousel .carousel-image {
    width: 50vw;
    height: 90vw;
  }
  .slide{
    padding: 1rem;
    border-radius: 10px;
    box-shadow: inset 0 0 1em #190429;
  }
  .text-carousel {
    height: 5rem;
    padding: 0;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .text-carousel button img {
    display: initial;
  }
  .right-arrow, .left-arrow {
    margin: 0 5vw;
  }

  .text-carousel h2 {
    font-size: 2.5rem;
    margin: 0;
  }

  .text-carousel h4,
  .text-carousel p,
  .text-carousel > div {
    display: none;
  }


  .btns-menu-mobile a,
  .text-carousel a {
    all: unset;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2rem;
  }

  .btns-menu-mobile a:hover,
  .text-carousel a:hover,
  .btns-menu-mobile a:hover::before,
  .text-carousel a:hover::before,
  .btns-menu-mobile a::before,
  .text-carousel a::before {
    all: unset;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2rem;
  }
  footer {
    flex-direction: column;
  }
}

/* 768px x 432px*/
@media (min-width: 768px) and (max-width: 991.98px) {
  html {
    font-size: 6px;
  }
  li a {
    font-size: 1.2rem;
    border-radius: 5px;
  }
  .text-carousel a {
    font-size: 1.2rem;
    border-radius: 5px;
  }
  .div-stacks {
    gap: 3.05vw;
  }
  li a:hover {
    font-size: 1.3rem;
  }
  .text-carousel a:hover {
    font-size: 1.3rem;
  }
}

/* 992px x 558px*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  html {
    font-size: 8px;
  }
  li a {
    font-size: 1.2rem;
    border-radius: 7px;
  }
  .text-carousel a {
    font-size: 1.2rem;
    border-radius: 7px;
  }
  li a:hover {
    font-size: 1.3rem;
  }
  .text-carousel a:hover {
    font-size: 1.3rem;
  }
}

/* 1200px x 675px*/
@media (min-width: 1200px) and (max-width: 1281.98px) {
  html {
    font-size: 10px;
  }
  li a {
    font-size: 1.2rem;
    border-radius: 8px;
  }
  .text-carousel a {
    font-size: 1.2rem;
    border-radius: 8px;
  }
  li a:hover {
    font-size: 1.3rem;
  }
  .text-carousel a:hover {
    font-size: 1.3rem;
  }
}

/* 1281px x 720px*/
@media (min-width: 1281px) and (max-width: 1359.98px) {
  html {
    font-size: 11px;
  }
  li a {
    font-size: 1.1rem;
    border-radius: 8px;
  }
  .text-carousel a {
    font-size: 1.1rem;
    border-radius: 8px;
  }
  li a:hover {
    font-size: 1.2rem;
  }
  .text-carousel a:hover {
    font-size: 1.2rem;
  }
}

/* 1360px x 765px*/
@media (min-width: 1360px) and (max-width: 1439.98px) {
  html {
    font-size: 12px;
  }
}

/* 1440px x 810px*/
@media (min-width: 1440px) and (max-width: 1599.98px) {
  html {
    font-size: 13px;
  }
}

/* 1600 x 900*/
@media (min-width: 1600px) and (max-width: 1900.98px) {
  html {
    font-size: 13px;
  }
}
